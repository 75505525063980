import React from "react"
import { Link } from "gatsby"
import badgeImg from "../../assets/images/abm-badge.png"
import badgeABA from "../../assets/images/about/img-finalist-aba-award-20222.png"
import badgefinal from "../../assets/images/about/AwardFinal.png"


const Badge = () => {

  return (
    <div className="wr-badge conflict mb-5 mb-md-4 mb-xl-4">
        <div className="container">
            <img src={badgefinal} className= "right-c" alt="Award Final" />
            <img src={badgeImg} className= "left-b" alt="ABW 5-Star Mortgage Innovator 2022 badge" />
            <img src={badgeABA} className= "left-a" alt="Finalist ABA Award 2022 Badge" />
            <div className="b-text">
                    AND MANY OTHER <br />
                    AWARDS
                </div>
        </div>
    </div>
  )
}

export default Badge
