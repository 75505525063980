import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navbar from "../components/App/Navbar"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import SEO from "../components/App/SEO"
import WhatIsSMSF from "../components/Smsf/whatIsSmsf"
import Offersmsf from "../components/Smsf/offersmsf"
import WhenShouldI from "../components/Smsf/whenShouldI"
import GetApproval from "../components/Smsf/getApproval"
import QuickFaq from "../components/Smsf/quickFaq"
import Faqguid from "../components/Smsf/Faqguid"
import CTABanner from "../components/Common/CTABanner"
import TermsAndCondition from "../components/Smsf/termsAndCondition"
import Footer from "../components/App/Footer"

const Home = props => {
  const responseData = useStaticQuery(graphql`
    query GetLandingRatesQuery {
      allStrapiRateCards(
        filter: {
          Rate_Name: {
            in: ["Interest_Rate_Landing_Page", "Comparison_Rate_Landing_Page"]
          }
        }
      ) {
        edges {
          node {
            Rate_Name
            Rate_Value
          }
        }
      }
    }
  `)

  const interestRate_landing =
    responseData.allStrapiRateCards.edges.find(
      edge => edge.node.Rate_Name === "Interest_Rate_Landing_Page"
    )?.node?.Rate_Value || "N/A"

  const comparison_landing =
    responseData.allStrapiRateCards.edges.find(
      edge => edge.node.Rate_Name === "Comparison_Rate_Landing_Page"
    )?.node?.Rate_Value || "N/A"

  return (
    <Layout>
      <SEO />
      <Navbar />
      <Banner
        HeroValue={interestRate_landing}
        ComparisonRate={comparison_landing}
      />
      <div className="page-smsf landing-page-content">
        <WhatIsSMSF />
        <Offersmsf />
        <WhenShouldI />
        <GetApproval />
        <QuickFaq />
        <Faqguid />
        <CTABanner />
        <TermsAndCondition />
      </div>
      <Footer />
    </Layout>
  )
}

export default Home
