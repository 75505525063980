import React from "react"
import ReactWOW from "react-wow"
import Badges from "../App/Badge"
import Available from "../../assets/images/smsf/available.svg"
import Residential from "../../assets/images/smsf/residential.svg"
import NoFee from "../../assets/images/smsf/noFee.svg"
import Easy from "../../assets/images/smsf/easy.svg"
import Fast from "../../assets/images/smsf/fast.svg"
import IconDollar from "../../assets/images/smsf/logo-dollar.svg"
import IconCalc from "../../assets/images/smsf/logo-calculator.svg"

const Banner = (props) => {
  return (
    <div className="">
      <div className="home-hero-banner section-top side-banner">
        <div className="container hero">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6 col-md-12">
              <div className="main-banner-content">
                <div>
                  <h1>
                    Great SMSF Rates
                    <br />
                    Start Here! <sup className="trademark mt-1">TM</sup>
                  </h1>
                  <h2 className="">Experts in SMSF Property Lending.</h2>
                  <div className="banner-list">
                    <div className="my-3 d-flex align-items-center">
                      <span className="mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_1674"
                                data-name="Rectangle 1674"
                                width="20"
                                height="20"
                                transform="translate(139 369)"
                                fill="#fff"
                                stroke="#707070"
                                stroke-width="1"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Mask_Group_3"
                            data-name="Mask Group 3"
                            transform="translate(-139 -369)"
                            clip-path="url(#clip-path)"
                          >
                            <g
                              id="speech-bubble"
                              transform="translate(139 369.137)"
                            >
                              <g
                                id="Group_1504"
                                data-name="Group 1504"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_3118"
                                  data-name="Path 3118"
                                  d="M10.077.205A9.99,9.99,0,0,0,0,10.156v8.862a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,9.993,9.932,9.932,0,0,0,10.077.205Z"
                                  transform="translate(0 -0.205)"
                                  fill="#0ed2b3"
                                />
                              </g>
                              <g
                                id="Group_1505"
                                data-name="Group 1505"
                                transform="translate(0 4.429)"
                              >
                                <path
                                  id="Path_3119"
                                  data-name="Path 3119"
                                  d="M18.346,6.849A15.46,15.46,0,0,1,3.36,18.523,15.5,15.5,0,0,1,0,18.156v3.077a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,12.207a9.72,9.72,0,0,0-1.654-5.358Z"
                                  transform="translate(0 -6.849)"
                                  fill="#0ab69f"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="b-list-item">100% Offset account​</span>
                    </div>
                    <div className="my-3 d-flex align-items-center">
                      <span className="mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_1674"
                                data-name="Rectangle 1674"
                                width="20"
                                height="20"
                                transform="translate(139 369)"
                                fill="#fff"
                                stroke="#707070"
                                stroke-width="1"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Mask_Group_3"
                            data-name="Mask Group 3"
                            transform="translate(-139 -369)"
                            clip-path="url(#clip-path)"
                          >
                            <g
                              id="speech-bubble"
                              transform="translate(139 369.137)"
                            >
                              <g
                                id="Group_1504"
                                data-name="Group 1504"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_3118"
                                  data-name="Path 3118"
                                  d="M10.077.205A9.99,9.99,0,0,0,0,10.156v8.862a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,9.993,9.932,9.932,0,0,0,10.077.205Z"
                                  transform="translate(0 -0.205)"
                                  fill="#0ed2b3"
                                />
                              </g>
                              <g
                                id="Group_1505"
                                data-name="Group 1505"
                                transform="translate(0 4.429)"
                              >
                                <path
                                  id="Path_3119"
                                  data-name="Path 3119"
                                  d="M18.346,6.849A15.46,15.46,0,0,1,3.36,18.523,15.5,15.5,0,0,1,0,18.156v3.077a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,12.207a9.72,9.72,0,0,0-1.654-5.358Z"
                                  transform="translate(0 -6.849)"
                                  fill="#0ab69f"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="b-list-item">
                        Residential & Commercial Properties
                      </span>
                    </div>
                    <div className="my-3 d-flex align-items-center">
                      <span className="mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_1674"
                                data-name="Rectangle 1674"
                                width="20"
                                height="20"
                                transform="translate(139 369)"
                                fill="#fff"
                                stroke="#707070"
                                stroke-width="1"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Mask_Group_3"
                            data-name="Mask Group 3"
                            transform="translate(-139 -369)"
                            clip-path="url(#clip-path)"
                          >
                            <g
                              id="speech-bubble"
                              transform="translate(139 369.137)"
                            >
                              <g
                                id="Group_1504"
                                data-name="Group 1504"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_3118"
                                  data-name="Path 3118"
                                  d="M10.077.205A9.99,9.99,0,0,0,0,10.156v8.862a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,9.993,9.932,9.932,0,0,0,10.077.205Z"
                                  transform="translate(0 -0.205)"
                                  fill="#0ed2b3"
                                />
                              </g>
                              <g
                                id="Group_1505"
                                data-name="Group 1505"
                                transform="translate(0 4.429)"
                              >
                                <path
                                  id="Path_3119"
                                  data-name="Path 3119"
                                  d="M18.346,6.849A15.46,15.46,0,0,1,3.36,18.523,15.5,15.5,0,0,1,0,18.156v3.077a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,12.207a9.72,9.72,0,0,0-1.654-5.358Z"
                                  transform="translate(0 -6.849)"
                                  fill="#0ab69f"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="b-list-item">Non Bank Lender</span>
                    </div>
                    <div className="my-3 d-flex align-items-center">
                      <span className="mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_1674"
                                data-name="Rectangle 1674"
                                width="20"
                                height="20"
                                transform="translate(139 369)"
                                fill="#fff"
                                stroke="#707070"
                                stroke-width="1"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Mask_Group_3"
                            data-name="Mask Group 3"
                            transform="translate(-139 -369)"
                            clip-path="url(#clip-path)"
                          >
                            <g
                              id="speech-bubble"
                              transform="translate(139 369.137)"
                            >
                              <g
                                id="Group_1504"
                                data-name="Group 1504"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_3118"
                                  data-name="Path 3118"
                                  d="M10.077.205A9.99,9.99,0,0,0,0,10.156v8.862a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,9.993,9.932,9.932,0,0,0,10.077.205Z"
                                  transform="translate(0 -0.205)"
                                  fill="#0ed2b3"
                                />
                              </g>
                              <g
                                id="Group_1505"
                                data-name="Group 1505"
                                transform="translate(0 4.429)"
                              >
                                <path
                                  id="Path_3119"
                                  data-name="Path 3119"
                                  d="M18.346,6.849A15.46,15.46,0,0,1,3.36,18.523,15.5,15.5,0,0,1,0,18.156v3.077a.92.92,0,0,0,.926.913H9.911A9.99,9.99,0,0,0,20,12.207a9.72,9.72,0,0,0-1.654-5.358Z"
                                  transform="translate(0 -6.849)"
                                  fill="#0ab69f"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="b-list-item">Established 22 Years</span>
                    </div>
                  </div>
                  <ReactWOW delay=".05s" animation="fadeInUp">
                    <div className="btn-box-new">
                      <span>
                        <a
                          href="/applynow"
                          className="default-btn mid-width-btn primary-btn btn-lg mb-2 mr-lg-2"
                        >
                          <div className="d-flex align-items-center">
                            <div className="r-part">It's Free</div>
                            <div className="m-part"> Apply Now</div>
                            {/* <svg
                              enable-background="new 0 0 32 32"
                              height="32px"
                              id="Слой_1"
                              version="1.1"
                              viewBox="0 0 32 32"
                              width="32px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                clip-rule="evenodd"
                                d="M32,16.009c0-0.267-0.11-0.522-0.293-0.714  l-9.899-9.999c-0.391-0.395-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428l8.193,8.275H1c-0.552,0-1,0.452-1,1.01  s0.448,1.01,1,1.01h27.586l-8.192,8.275c-0.391,0.394-0.39,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l9.899-9.999  C31.894,16.534,31.997,16.274,32,16.009z"
                                fill="#121313"
                                fill-rule="evenodd"
                                id="Arrow_Forward"
                              />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                            </svg> */}
                          </div>
                        </a>
                      </span>
                    </div>
                    <div class="d-flex">
                      <a
                        href={`${process.env.GATSBY_OLAP_BASE_URL}/SMSF/Quote`}
                        target="_blank"
                        className="default-btn calculator-btn mb-2 mb-md-0 mr-md-1"
                      >
                        {/* <div class="icon-dollar"></div> */}
                        <img
                          src={IconDollar}
                          alt="Icon"
                          class="icon-calculators mr-1"
                        ></img>
                        Quick Quote
                      </a>
                      <a
                        href={`${process.env.GATSBY_OLAP_BASE_URL}/SMSF/Quote`}
                        target="_blank"
                        className="default-btn calculator-btn"
                      >
                        <img
                          src={IconCalc}
                          alt="Icon"
                          class="icon-calculators mr-2"
                        ></img>
                        SMSF Property Tool
                      </a>
                    </div>
                    <div className="smsf-accredited-container d-flex">
                      <div className="smsf-accredited"></div>
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 col-md-12 d-lg-block d-none">
              <div className="banner-left">
                <div className="interest-comparison">
                  <div className="interest-comparison-wrapper">
                    <div className="label-value interest-side">
                      <p className="rate-label mb-1">Interest Rate from</p>
                      <div className="value-wrapper">
                        <h1>{props.HeroValue}</h1>
                        <div className="percentage-sign">
                          <p>%</p>
                          <span>P.A</span>
                        </div>
                      </div>
                    </div>

                    <div className="label-value comparison-side">
                      <p className="rate-label mb-1">Comparison Rate*</p>
                      <div className="value-wrapper">
                        <h1>{props.ComparisonRate}</h1>
                        <div className="percentage-sign">
                          <p>
                            %<span>P.A</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="wrap-badges">
                  <Badges />
                </div> */}
                <div className="d-flex justify-content-center">
                  <p className="no-hidden-fees">NO HIDDEN FEES</p>
                </div>
                <div className="accountant-login-container d-flex justify-content-start align-items-center">
                  <div className="logo-container d-flex mr-4">
                    {/* commented out until class integration */}
                    <div className="bgl-logo"></div>
                    {/* <div className="vertical-split plr-2"></div>
                    <div className="class-logo ml-2"></div> */}
                  </div>
                  <a
                    href={`${process.env.GATSBY_SMSF_BASE_URL}`}
                    target="_blank"
                    className="login-link pr-5"
                  >
                    Accountant login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* hidden lg */}
      <div className="d-lg-none d-md-block banner-section2">
        <div className="banner-left">
          <div className="interest-comparison">
            <div className="interest-comparison-wrapper">
              <div className="label-value interest-side">
                <p className="rate-label mb-1">Interest Rate</p>
                <div className="value-wrapper">
                  <h1>{props.HeroValue}</h1>
                  <div className="percentage-sign">
                    <p>
                      %<span>P.A</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="label-value comparison-side">
                <p className="rate-label mb-1">Comparison Rate</p>
                <div className="value-wrapper">
                  <h1>{props.ComparisonRate}</h1>
                  <div className="percentage-sign">
                    <p>
                      %<span>P.A</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className="no-hidden-fees">No hidden fees</p> */}
          </div>
          {/* <div className="wrap-badges">
            <Badges />
          </div> */}
          <div className="d-flex justify-content-center">
            <p className="no-hidden-fees">NO HIDDEN FEES</p>
          </div>
          <div className="accountant-login-container d-flex justify-content-start align-items-center">
            <div className="logo-container d-flex mr-4">
              <div className="bgl-logo"></div>
              {/* commented out until class integration */}
              {/* <div className="vertical-split plr-2"></div>
              <div className="class-logo ml-2"></div> */}
            </div>
            <a
              href="https://smsf.staging.oxygenloans.com.au/"
              target="_blank"
              className="login-link pr-5"
            >
              Accountant login
            </a>
          </div>
        </div>
      </div>
      <div className="container hero">
        <section className="home-cat-section">
          <ReactWOW delay=".05s" animation="fadeInUp">
            <div className="row justify-content-center justify-content-md-center justify-content-lg-between">
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-4 mb-lg-0">
                <div className="icon-text-group">
                  <img
                    src={Available}
                    alt="Available for Purchase & Refinancee"
                  />
                  <p>Available for Purchase & Refinance</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-4 mb-lg-0">
                <div className="icon-text-group">
                  <img
                    src={Residential}
                    alt="Residential & Commercial Properties"
                  />
                  <p>Residential & Commercial Properties​</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-4 mb-lg-0">
                <div className="icon-text-group">
                  <img
                    src={NoFee}
                    alt="No application fee & no settlement fee"
                  />
                  <p>No application fee & no settlement fee​</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-0 mb-lg-0">
                <div className="icon-text-group">
                  <img src={Easy} alt="Easy Application process" />
                  <p>Easy Application process​</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-0 mb-md-0 mb-lg-0">
                <div className="icon-text-group">
                  <img src={Fast} alt="Fast & simple Refinance approvals​<" />
                  <p>Fast & simple Refinance approvals​</p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </section>
      </div>
    </div>
  )
}

export default Banner
